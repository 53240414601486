import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { RegisterAction,validateLicAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Register = () => {
	
	
  const [form, setForm] = useState({ email: '', lic_key: '', role_id: '' });
  const [validationError, setvalidationError] = useState({});	
  if (Cookies.get('loginSuccessIXRFront')) {
		window.location.href = `${config.baseUrl}dashboard`;
  }
  const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
  const inputHandlerrole_id = async (e) => {
		 setrole_id(e.target.value); 
	}

  function validate() {
    let emailError = "";
    let lic_keyError = "";
    let role_idError = "";

    if (form.email === '') {
      emailError = "Username is required."
    }
    if (form.lic_key === '') {
        lic_keyError = "License key is required."
    }
     if (form.role_id === '') {
        role_idError = "Role is required."
    }
    if (emailError || lic_keyError || role_idError) {
        setvalidationError({
          emailError, lic_keyError, role_idError
        })
        return false
    } else {
		 setvalidationError({
          emailError, lic_keyError, role_idError
        })
        return true
    }
}
  
  const validate_Lic = async () => {
		let lic_key	=	form.lic_key;	
		let res = await validateLicAction({ 'lic_key': lic_key });	  
		if (res.success) {
			return true
		} else {
			return false
		}
	} 
  	
  const SubmitForm = async (e) => {
    e.preventDefault()
    const isValid = validate();
    if (!isValid) {
  
    }
    else {
			const isValid_lic = await validate_Lic();
			if (!isValid_lic) {				
				toast.error('Invalid license key');
			}
			else
			{		
				let res = await RegisterAction(form);
				if (res.success) {
					toast.success(res.msg);            
					Cookies.set('loginSuccessIXRFront', JSON.stringify(res.data));
					setTimeout(() => {
						window.location.href = `${config.baseUrl}registergeneral`;
					}, 1200);
				} else {
					toast.error(res.msg);
				}
			}
    }
}

  return (
  
    <>
    
	<div className="dasbord">
		<Toaster />     
		<div className="container">
			<div className="dashboard-menu">
				<div className="row">
					<div className="col-lg-6">
						<div className="laft-post">
							<div className="iXR-new">
								<img src={`${config.baseUrl}img/iXRlabs.png`} />
							</div>
							<div className="engine">
								<img src={`${config.baseUrl}img/heart-image.png`}  />
							</div>
						</div>
						
					</div>
					<div className="col-lg-6">
						<div className="password-field">
						  <div className="registration-main">
							<div className="range-item">
								<ul className="list-inline">
								  <li className="list-item active">
									<span>Account</span>
								  </li>
								  <li className="list-item">
									<span>General</span>
								  </li>
								  <li className="list-item ">
									<span>Education</span>
								  </li>
								  <li className="list-item ">
									<span>Pin</span>
								  </li>
								  <li className="list-item">
									<span>Profile</span>
								  </li>
								  <li className="list-item ">
									<span>Finish</span>
								  </li>
								</ul>
							 
							  </div>
						  </div>
						  <div className="account-main">
								<div className="login-registration-step">
								<div className="Registration">
									<h2>Create Account</h2>
								</div>
								<div className="form-section">
									<form>
										<div className="form-group">
											<label for="exampleDropdownFormPassword1">Email</label>
											<div className="icon-block">
												<span className="lock-icon"><img src={`${config.baseUrl}img/email.png`} /></span>
												<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="Email" onChange={inputHandler} name="email" autocomplete="off"  />
											</div>
											<span className="invalid-feedback">{validationError.emailError}</span>
										</div>
										<div className="form-group">
										<label>License key</label>
										<div className="icon-block">
											<span className="lock-icon"><img src={`${config.baseUrl}img/key.png`} /></span>
											<input className={validationError.lic_keyError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="License Key" onChange={inputHandler} name="lic_key"  />
											
										</div>
										
									</div>
										
										<div className="form-group">
											<label for="exampleFormControlSelect1">Role</label>
											<div className="icon-block">
												<span className="lock-icon"><img src={`${config.baseUrl}img/role.png`} /></span>
												<select className={validationError.role_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="role_id" name="role_id" onChange={inputHandler} >
												<option value=''> Select</option>
												<option value={`${config.STUDENT_ROLE_ID}`}> Learner</option>
												</select>
											</div>
											<span className="invalid-feedback">{validationError.role_idError}</span>
										</div>
										
										<div className="Sign-in">
											<button className="sign-up-btn"  type="submit" onClick={SubmitForm}  >Sign up</button>
										</div>
										<div className="sign-up">
									<a href={`${config.baseUrl}`}>Already have account ? <span>Sign in</span></a>
								</div>
									
									</form>
								</div>
								 </div>
						  </div>
						  
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
	
	</div>
    

    </>
  )

}
export default Register;
