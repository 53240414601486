import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { getBranchesListAction,getCollegesListAction,getDomainsListAction,getBatchesListAction, getUserDetailsAction,RegisterEducationAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Registereducation = () => {
	
	const [UserDetails, setUserDetails] = useState({});
	
	const [BranchesList, setBranchesList] = useState([]);
	
	const [CollegesList, setCollegesList] = useState([]);
	
	const [DomainsList, setDomainsList] = useState([]);
	
	const [BatchesList, setBatchesList] = useState([]);
	
	const [years, setyears]				= useState([]);
	
	const [form, setForm] = useState({ user_id: '',college_id: '', course_id: '', branch_id: '', year: '', batch_id: '' });
	const [validationError, setvalidationError] = useState({});
	const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
	

	const getDomainsList = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			let data = res.data;
			setDomainsList(data);	
		}
	}
	
  const generateYears= async () =>{
		const currentYear = new Date().getFullYear();
		const years = [];
		for (let year = 2018; year <= currentYear; year++) {
		  years.push(year);
		}
		setyears(years);		
	  }
	
	const getBranchesList = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			let data = res.data;
			setBranchesList(data);	
		}
	}
	
	const getCollegesList = async () => {
		let res = await getCollegesListAction();
		if (res.success) {
			let data = res.data;
			setCollegesList(data);	
		}
	}	
	
	const getBatchesList = async () => {
		let res = await getBatchesListAction();
		if (res.success) {
			let data = res.data;
			setBatchesList(data);	
		}
	}	
	
	
			
	const check_valid_stage = async () => {
		
		if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		}
		else
		{
			let cookie_user_id	=	loginData.id;
		
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
		  
			if (res.success) {
				
				setUserDetails(res.data);
			  					
				let data = res.data;
				setForm((old) => {
					return { ...old, user_id: data.id, branch_id: data.branch_id, college_id: data.college_id, course_id: data.course_id, batch_id: data.batch_id, year: data.year };
				});

			}
		
		} 
   
    } 
    
	useEffect(() => {
		generateYears();
		getDomainsList();
        getBranchesList();
        getCollegesList();
        getBatchesList();        
		check_valid_stage();
	}, []);
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const handleKeyPress = (e) => {
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  

	function validate() {
		let college_idError = "";
		let course_idError = "";
		let branch_idError = "";
		let yearError = "";
		let batch_idError = "";

		if (form.college_id === '' || form.college_id === null) {
			college_idError = "College is required."
		}
		if (form.course_id === '' || form.course_id === null) {
			course_idError = "Course is required."
		}
		if (form.branch_id === '' || form.branch_id === null) {
			branch_idError = "Branch is required."
		}
		if (form.year === '' || form.year === null) {
			yearError = "year is required."
		}
		if (form.batch_id === '' || form.batch_id === null) {
			batch_idError = "Batch is required."
		}
		if (college_idError || course_idError || branch_idError || yearError || batch_idError) {
			setvalidationError({
			  college_idError, course_idError, branch_idError, yearError, batch_idError
			})
			return false
		} else {
			return true
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {			
			
			let res = await RegisterEducationAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}registerpin`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
			
		}
	}


  return (
  
    <>
    
	<div className="dasbord">
		<Toaster /> 
		
		<div className="container">
		<div className="dashboard-menu">
		<div className="row">
			<div className="col-lg-6">
				<div className="laft-post">
					<div className="iXR-new">
						<img src={`${config.baseUrl}img/iXRlabs.png`} />
					</div>
					<div className="engine">
						<img src={`${config.baseUrl}img/heart-image.png`}  />
					</div>
				</div>
				
			</div>
			<div className="col-lg-6">
				<div className="password-field">
				  
				  <div className="registration-main">
					<div className="range-item">
						<ul className="list-inline">
						  <li className="list-item active">
							<span>Account</span>
						  </li>
						  <li className="list-item active">
							<span>General</span>
						  </li>
						  <li className="list-item active">
							<span>Education</span>
						  </li>
						  <li className="list-item ">
							<span>Pin</span>
						  </li>
						  <li className="list-item">
							<span>Profile</span>
						  </li>
						  <li className="list-item ">
							<span>Finish</span>
						  </li>
						</ul>
					 
					  </div>
				  </div>
						  
		<div className="account-main">
			<div className="Registration">
			  <h2>Education</h2>
			</div>
			<div className="form-section">
			<form>
			  <div className="form-group">
				<label>College</label>
				<div className="icon-block">
					<span className="lock-icon"><img src={`${config.baseUrl}img/collage.png`} /></span>
					<select className={validationError.college_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="college_id" name="college_id" value={form.college_id} onChange={inputHandler}>
					  <option value="">Select College</option>
						{
							CollegesList.map((xcollege,index)=>{
								return(
									<option value={xcollege.id} >{xcollege.title}</option>
								)
							})
						}
					</select>
				</div>
				<span className="invalid-feedback">{validationError.college_idError}</span>
			  </div>
			  <div className="form-group">
					<label>Course</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/course.png`} /></span>
						<select className={validationError.course_idError ? 'form-control is-invalid' : 'form-control'} id="course_id" name="course_id"  value={form.course_id} onChange={inputHandler} >
						<option>Select Course</option>
						{
							DomainsList.map((xdomain,index)=>{
								return(
									<option value={xdomain.id} >{xdomain.title}</option>
								)
							})
						}
						</select>
					</div>
					<span className="invalid-feedback">{validationError.course_idError}</span>
			  </div>
			  <div className="form-group">
				<label>Branch</label>
				<div className="icon-block">
					<span className="lock-icon"><img src={`${config.baseUrl}img/branch.png`} /></span>
					
					<select className={validationError.branch_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="branch_id" name="branch_id" value={form.branch_id} onChange={inputHandler}>
					  <option value="">Select Branch</option>
						{
							BranchesList.map((xbranch,index)=>{
								return(
									<option value={xbranch.id} >{xbranch.title}</option>
								)
							})
						}
					</select>
					
			  </div>
			  <span className="invalid-feedback">{validationError.branch_idError}</span>
			  </div>
			  <div className="row">
				  <div className="form-group col-md-6">
					<label>Year</label>
					<div className="icon-block year-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/year.png`} /></span>
						<select className={validationError.yearError ? 'form-control is-invalid' : 'form-control'} id="year" name="year"  onChange={inputHandler} value={form.year}>
							  <option value="">Select Year</option>
							  {years.map((year) => (
								<option key={year} value={year}>{year}</option>
							  ))}
							</select>
					</div>
					<span className="invalid-feedback">{validationError.yearError}</span>
				  </div>
				  <div className="form-group col-md-6">
					<label>Batch</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/batch.png`} /></span>
						<select className={validationError.batch_idError ? 'form-control is-invalid' : 'form-control'} id="batch_id" name="batch_id" value={form.batch_id} onChange={inputHandler}>
						  <option value="">Select Batch</option>
							{
								BatchesList.map((xbatch,index)=>{
									return(
										<option value={xbatch.id} >{xbatch.title}</option>
									)
								})
							}
						</select>
					</div>
					<span className="invalid-feedback">{validationError.batch_idError}</span>
				  </div>
				
			</div>
		 </form>
				
			<div className="main-item-btn">		
				<div className="solid-btn">
					<button className="login-btn btn-bg"  type="submit" onClick={SubmitForm}  >Next<span><i className="fa-solid fa-arrow-right"></i></span></button>
				</div>
			</div>
	</div>
</div>

</div>
</div>
</div>
</div>
</div>

	</div>

    </>
  )

}
export default Registereducation;
