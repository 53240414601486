import React, { Component, useEffect, useState, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { getUserDetailsAction,validateOtpAction,RegisterGeneralAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
import avt from '../assets/rounded.png'

const Registergeneral = () => {
	
	const [userdata, setuserdata] = useState({});	
	const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
    const fileInputRef = useRef(null);	
	const [form, setForm] = useState({ user_id: '', email: '', first_name: '', last_name: '', phone: '', otp: '', photo: '', password : '', cpassword : '' });
	const [validationError, setvalidationError] = useState({});
	const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
	
	const handleUploadClick = () => {
    fileInputRef.current.click();
  };
		
	const [Passwordtype, setPasswordType] = useState('password');
	const [Passwordicon, setPasswordIcon] = useState(config.baseUrl+'img/eyes.png');
	
	const handlePasswordToggle = () => {
	   if (Passwordtype==='password'){
		  setPasswordIcon(config.baseUrl+'img/eye.png');
		  setPasswordType('text')
	   } else {
		  setPasswordIcon(config.baseUrl+'img/eyes.png');
		  setPasswordType('password')
	   }
	}	
	const check_valid_stage = async () => {
		
		if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		}
		else
		{
			let cookie_user_id	=	loginData.id;
		
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
		  
			if (res.success) {
				
				setuserdata(res.data)
				
				if(res.data.reg_stage==2)
				{
					window.location.href = `${config.baseUrl}registereducation`;
				}
				else if(res.data.reg_stage==3)
				{
					window.location.href = `${config.baseUrl}registerpin`;
				}
				else if(res.data.reg_stage==4)
				{
					window.location.href = `${config.baseUrl}registerprofile`;
				}
				else if(res.data.reg_stage==5)
				{
					window.location.href = `${config.baseUrl}registerfinish`;
				}
				else if(res.data.reg_stage>5)
				{
					window.location.href = `${config.baseUrl}dashboard`;
				}
				
				setForm((old) => {
					return { ...old, ['email']: res.data.email }
				})
				
			}
		
		} 
   
    } 
    
	const validate_otp = async () => {		
		let cookie_user_id	=	loginData.id;
		let otp	=	form.otp;	
		let res = await validateOtpAction({ 'user_id': cookie_user_id, 'otp': otp });	  
		if (res.success) {
			return true
		} else {
			return false
		}
	} 
		
	useEffect(() => {
	  check_valid_stage();
	}, []);
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	

	function validate() {
		let emailError 		= "";
		let first_nameError = "";
		let last_nameError 	= "";
		let passwordError 	= "";
		let cpasswordError 	= "";
		let otpError 		= "";
		
		if (form.email === '') {
			emailError = "Email is required."
		}
		if (form.first_name === '') {
			first_nameError = "First name is required."
		}				
		if (form.password === '') {
			passwordError = "Password is required."
		}
		if (form.cpassword === '') {
			cpasswordError = "Confirm password is required."
		}	
		if(form.password  !== '' && form.cpassword !== '')	
		{
			if(form.password  !== form.cpassword)
			{				
				cpasswordError = "Confirm password not matched."
			}
		}	
		if (form.otp === '') {
			otpError = "OTP is required."
		}
		
		if (emailError || first_nameError || passwordError || otpError || cpasswordError) {
			setvalidationError({
			  emailError, first_nameError, passwordError, otpError, cpasswordError
			})
			return false
		} else {
			setvalidationError({
			  emailError, first_nameError, passwordError, otpError, cpasswordError
			})
			return true
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			
			const isValid_otp = await validate_otp();
			if (!isValid_otp) {
				
				toast.error('Invalid OTP');

			}
			else
			{
				form.user_id	=	loginData.id;					
				let res = await RegisterGeneralAction(form);
				if (res.success) {
					toast.success(res.msg);
					setTimeout(() => {
						window.location.href = `${config.baseUrl}registereducation`;
					}, 1200);
				} else {
					toast.error(res.msg);
				}
			}
		}
	}
	
	const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['photo']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }

  return (
  
    <>
    
	<div className="dasbord">
		<Toaster />     
		<div className="container">
		<div className="dashboard-menu">
		<div className="row">
		<div className="col-lg-6">
						<div className="laft-post">
							<div className="iXR-new">
								<img src={`${config.baseUrl}img/iXRlabs.png`} />
							</div>
							<div className="engine">
								<img src={`${config.baseUrl}img/heart-image.png`}  />
							</div>
						</div>
						
					</div>
					<div className="col-lg-6">
						<div className="password-field">
						<div className="registration-main">
							<div className="range-item">
								<ul className="list-inline">
								  <li className="list-item active">
									<span>Account</span>
								  </li>
								  <li className="list-item active">
									<span>General</span>
								  </li>
								  <li className="list-item ">
									<span>Education</span>
								  </li>
								  <li className="list-item ">
									<span>Pin</span>
								  </li>
								  <li className="list-item">
									<span>Profile</span>
								  </li>
								  <li className="list-item ">
									<span>Finish</span>
								  </li>
								</ul>
							 
							  </div>
						  </div>
						  
		<div className="account-main">
		<div className="Registration">
			<h2>General</h2>
		</div>
		<div className="form-section">
		<form>
			<div className="row">
			<div className="col-md-9">
				<div className="form-group">
					<label>Email</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/email.png`} /></span>
						<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="Email" value={userdata.email} onChange={inputHandler} name="email" readonly  />
						
					</div>
					
				</div>
				<div className="form-group">
					<label for="exampleDropdownFormPassword1">Password</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/lock.png`} /></span>
						<input className={validationError.passwordError ? 'form-control is-invalid' : 'form-control'} type={Passwordtype} placeholder="Password" onChange={inputHandler} name="password"  autocomplete="off" />
						<span className="span-right" onClick={handlePasswordToggle} >
						<img src={Passwordicon} />
						</span>
					</div>
					<span className="invalid-feedback">{validationError.passwordError}</span>
				</div>
				<div className="form-group">
					<label for="exampleDropdownFormPassword1">Confirm Password</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/lock.png`} /></span>
						<input className={validationError.cpasswordError ? 'form-control is-invalid' : 'form-control'} type="password" placeholder="Password" onChange={inputHandler} name="cpassword"  autocomplete="off" />
					</div>
					<span className="invalid-feedback">{validationError.cpasswordError}</span>
				</div>
			</div> 
			<div className="col-md-3">
				<div className="upload-post">
					<img src={image_preview ? image_preview : avt} />
					<div className="post-text">
						<h6 for="file-upload" onClick={handleUploadClick}>Upload Photo</h6>
						  <input onChange={PicChange} id="file-upload" accept="image/png, image/jpeg" type="file" name="profile" style={{ display: 'none' }} ref={fileInputRef} />
					</div> 
				</div>
			</div>
			</div>
			<div className="row">
			<div className="col-md-8">
				<div className="form-group">
					<label>Name</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/user.png`} /></span>
						<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="Name" onChange={inputHandler} name="first_name"  />					
					</div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="form-group">
					<label>Verify Email OTP</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/otp.png`} /></span>
						<input className={validationError.otpError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="OTP" onChange={inputHandler} name="otp"  />
					
					</div>										
				</div>
			</div>
			</div>
		</form>
		<div className="Sign-in btn-box">
			<button className="login-btn btn-bg"  type="submit" onClick={SubmitForm}  >Next<span><i className="fa-solid fa-arrow-right"></i></span></button>
		</div>
		
		</div>

		</div>
		</div>
		</div>
		</div>
		</div>
		</div>
	</div>

    </>
  )

}
export default Registergeneral;
