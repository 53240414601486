import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { getUserDetailsAction,RegisterprofileAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Registerprofile = () => {
	
	const [UserDetails, setUserDetails] = useState({});
	
	const [form, setForm] = useState({ user_id: '',username: '', user_pin: '' });
	const [validationError, setvalidationError] = useState({});
	const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
	
			
	const check_valid_stage = async () => {
		
		if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		}
		else
		{
			let cookie_user_id	=	loginData.id;
		
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
		  
			if (res.success) {
				
				setUserDetails(res.data);
			}
		
		} 
   
    } 
    
	useEffect(() => {
		check_valid_stage();
	}, []);
	
	

	const SubmitForm = async (e) => {
		
	window.location.href = `${config.baseUrl}registercongrats`;
				
	}

	

  return (
  
    <>
    
	<div className="dasbord">
		<Toaster /> 
		
		<div className="container">
		<div className="dashboard-menu">
		<div className="row">
		<div className="col-lg-6">
						<div className="laft-post">
							<div className="iXR-new">
								<img src={`${config.baseUrl}img/iXRlabs.png`} />
							</div>
							<div className="engine">
								<img src={`${config.baseUrl}img/heart-image.png`}  />
							</div>
						</div>
						
					</div>
			<div className="col-lg-6">
				<div className="password-field">
				  
				  <div className="registration-main">
					<div className="range-item">
						<ul className="list-inline">
						  <li className="list-item active">
							<span>Account</span>
						  </li>
						  <li className="list-item active">
							<span>General</span>
						  </li>
						  <li className="list-item active">
							<span>Education</span>
						  </li>
						  <li className="list-item active">
							<span>Pin</span>
						  </li>
						  <li className="list-item active">
							<span>Profile</span>
						  </li>
						  <li className="list-item ">
							<span>Finish</span>
						  </li>
						</ul>
					 
					  </div>
				  </div>
						  
			<div className="account-main">

			<div className="Registration">
				<h2>Profile Preview</h2>
			</div>
			<div className="form-section view-size">
				<form>

				<div className="user-idea">
					<div className="row align-items-end mb-3">
						<div className="col-sm-3">
							<div className="shapes-images">
							{UserDetails?.photo ?

							<img src={`${config.UserProfileimageUrl + UserDetails?.photo}`} />
							:
							<img src={`${config.baseUrl}img/rounded.png`} />
							}


							</div>
						</div>
						<div className="col-sm-9">
							<div className="form-group m-0">
								<label for="exampleDropdownFormPassword1">Name</label>
								<div className="icon-block">
									<span className="lock-icon"><img src={`${config.baseUrl}img/user.png`} /></span>
									<input type="text" className="form-control" value={UserDetails.first_name+' '+UserDetails.last_name} readonly />
								</div>
							</div>
						</div>
						
					</div>
				</div>


				<div className="form-group">
					<label>College</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/collage.png`} /></span>
						<input className="form-control" type="text"  value={UserDetails.college_title}  readonly />
					</div>
				</div>

				<div className="form-group">
					<label>Branch</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/branch.png`} /></span>
						<input className="form-control" type="text"  value={UserDetails.branch_title}  readonly />
					</div>
				</div>

				<div className="form-group">
					<label>Email</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/email.png`} /></span>
					<input className="form-control" type="text"  value={UserDetails.email}  readonly />
					</div>
				</div>


				</form>


				<div className="main-item-btn">
					<div className="solid-btn">
					<button className="login-btn btn-bg"  type="submit" onClick={SubmitForm}  >Finish</button>
					</div>
				</div>

			</div>
		</div>



</div>
</div>
</div>
</div>
</div>

    </div>
    
    </>
  )

}
export default Registerprofile;
