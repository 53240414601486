import React, { useEffect, useState } from 'react'
import { Link, useSearchParams  } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import Cookies from "js-cookie";
import { getUserModulesListAction, getUserDetailsAction, getUsersBranchesListAction, getUsersDomainsListAction,getUsersTopicListAction, getModulesTypesAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
import avt from '../assets/rounded.png'
const Modules = () => {
	 const [UserDetails, setusersData] = useState([]);
	 const [ModulesList, setModulesList] = useState([]);
	 const [BranchesList, setBranchesList] = useState([]);
	 const [DomainsList, setDomainsList] = useState([]);	
	 const [TopicsList, setTopicsList] = useState([]);
	 const [FilterTopicsList, setFilterTopicsList] = useState([]);	
	 const [moduletypeList, setmoduletypeList] = useState([]);
	 const [titleFilter, SettitleFilter] = useState('');
	 const [domainFilter, SetdomainFilter] = useState('');
	 const [branchFilter, SetbranchFilter] = useState('');
	 const [topicFilter, SettopicFilter] = useState('');			
	 const [moduleTypeFilter, SetmoduleTypeFilter] = useState('');	
	 const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	 useEffect(() => {
		getProfileAPI();
		getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();
		getModulesTypesAPI();  
		getModulesListAPI();
		setTabContentHeight();	
	 },[])
 
	const getProfileAPI = async () => {
		let res = await getUserDetailsAction({ user_id: loginData?.id });
		console.log(res);
		if (res.success) {
			setusersData(res.data);
		}
	};
	const getBranchesListAPI = async () => {
		let res = await getUsersBranchesListAction({user_id: loginData?.id});
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getUsersDomainsListAction({user_id: loginData?.id});
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getModulesTypesAPI = async () => {
		let res = await getModulesTypesAction();
		if (res.success) {
			setmoduletypeList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getUsersTopicListAction({user_id: loginData?.id});
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	const SettFilter = async (e) => {
        SettitleFilter(e.target.value);        
    }
	const SetMtFilter = async (e) => {
        SetmoduleTypeFilter(e.target.value);        
    }
    const SettopFilter = async (e) => {
        SettopicFilter(e.target.value);        
    } 
	const SetbrFilter = async (e) => {
        SetbranchFilter(e.target.value);
        SettopicFilter('');
        let searchBranch = e.target.value; 
		if(searchBranch != null){
			const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch)); 
			setFilterTopicsList(result); 		
		} 
		else
		{
			setFilterTopicsList([]);
		}       
    }
	const getModulesListAPI = async () => {
		let res = await getUserModulesListAction({title:titleFilter, branch:branchFilter, moduleType:moduleTypeFilter, topic:topicFilter,  user_id: loginData?.id});
		if (res.success){      
		  setModulesList(res.data);
		}
		else{
			setModulesList([]);
		}   
   };
   const FilterModules = async (e) => {		
        getModulesListAPI();       
    }
   const clearFilterModules = async (e) => {
	  SettitleFilter('');  
	  SetmoduleTypeFilter(''); 
	  SetbranchFilter('');       
	  SettopicFilter('');      
      let list = await getUserModulesListAction({title:'',branch:'',moduleType:'',topic:'', user_id: loginData?.id});
		if (list.success) {
			setModulesList(list.data);	
		}
		else
		{
			setModulesList([]);	
		}      
    }
    
    const setTabContentHeight = () => {
	  const bodyHeight = document.body.scrollHeight;
	  const tabContent = document.querySelector('div.overview');
	  const mainContent = document.querySelector('div.search-module');
	  const maxioContent = document.querySelector('div.max-oi');
	  if (tabContent) {
		  if(windowWidth > 1199)
		  {
			  tabContent.style.height = `${bodyHeight - 300}px`;
			  mainContent.style.height = `${bodyHeight - 170}px`;
			  maxioContent.style.height = `${bodyHeight - 320}px`;
		   }		
	  }
	};
   
  return (
    <>    
    <div id="main" className="ml-250">
		<div className="dashboar-template">
		 <Sidebar />
		  <div className="dashboar-left">
			<div className="container-fluid">
			  <div className="dashboar-top">
				   <div class="board-heading">
					   <div class="onclick">
						<a href="#" class="openbtn" onclick="openNav()"><img src={`${config.baseUrl}img/dashboard.png`} /></a>
						<div class="on-contant">
						<a href="#">Dashboard</a>
					  </div>
					  </div>      
				  <div class="search">
					<a href="#">> Modules</a>
				  </div>
			</div>
		   <div className="app-header">
			<div className="app-header-left">
			  <div className="setting-item">
				<a href="#">
			  <img src={`${config.baseUrl}img/leaderboard.png`} /></a>
			</div>
			  <div className="setting-item">
				<a href="#">
			  <img src={`${config.baseUrl}img/setting.png`} />
			</a>
			</div>
			<div className="client-post">
			  <a href="#">
			  {UserDetails?.photo ?
				<img src={`${config.UserProfileimageUrl + UserDetails?.photo}`} />
				:
				<img src={`${config.baseUrl}img/rounded.png`} />
				}
			  </a>
			</div>
			<div className="client-heading">
			  <a href="#">{UserDetails?.first_name}</a>
			</div>
			</div>
		</div>
		</div>
		<div className="overview">
		<div className="search-module search-module-main">
			 <div className="overview-heading">
				<h6>Search Module</h6>
			  </div>
		<div className="search-field">
		  <div className="main-flex">
		  <div className="search-flex">
		  <div className="preview-email">
			<div className="form-group">
			 <input type="text" name="title" placeholder="Module Title..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SettFilter} value={titleFilter}/>			
			</div>
			</div>			
		  <div className="search-form">
			<div className="form-group">
			<select className="form-control" id="branch_id" name="branch_id" onChange={SetbrFilter} value={branchFilter}>
			  <option value="">Select Branch</option>
			  {
				BranchesList.map((item)=>{
					return(
						<option value={item.id}>{item.title}</option>
					)
				})
				}	
				</select>
		  </div>
		  </div>
		  <div className="search-form">
			<div className="form-group">
			<select className="form-control" id="topic_id" name="topic_id" onChange={SettopFilter} value={topicFilter}>
			  <option value="">Select Topic</option>					
				{
					FilterTopicsList.map((item,index)=>{
						return(
							<option value={item.id}>{item.title}</option>
						)
					})
				}						
			</select>			
		  </div>
		  </div>
		  <div className="search-form">
			<div className="form-group">
			<select className="form-control" id="module_type_id" name="module_type_id" onChange={SetMtFilter} value={moduleTypeFilter}>
			  <option value="">Select Module Type</option>
				{
					moduletypeList.map((item)=>{
						return(
							<option value={item.id}>{item.title}</option>
						)
					})
				}
			</select>  
		  </div>
		  </div>
		</div>
		<div className="item-search">
		  <div className="site-post">
			<a href="javascript:;" onClick={() => clearFilterModules()}><img src="img/clear.png"  /></a>
		  </div>
		  <div className="site-post">
			<a href="javascript:;" onClick={() => FilterModules()}><img src="img/search.png"  /></a>
		  </div>
		  </div>
		</div>
		</div>

		<div className="fluid-section search-module-tbl">
		  <div className="topic-table">
			<div className="max-oi">
		  <div className="table-responsive">
		<table className="table table-condensed table-striped table-c ">
		<thead>
			<tr>         
			  <th>Module</th>
			  <th>Module Name</th>
			  <th>Topic</th>
			  <th className="mod-status">Status</th>
			</tr>
		</thead>
		<tbody>     
			{ ModulesList.length> 0 ?
				ModulesList.map((item, index) => (
					<>
						<tr key={index}  className="status">
							<td className="aroplane">
								<img src={`${config.imageUrl + item.image_url}`} style={{ width: '100px', height: 'auto' }}  />
							</td>
							<td> 
								<Link to={`${config.baseUrl}module/` + item.id}>
								{item.title}
								</Link>
							</td>
							<td>{item.topic_title}</td>
							<td className="mod-status">{ item.is_active === 1 ?
								<>
								<a className="completed-btn" href="#">Active</a>
								</>
								:
								<>
								<a className="completed-btn not" href="#">Inactive</a>
								</>
								}</td>							
						</tr>
						</>
					)
				)
				:
				(
					<tr><td colspan="8" className="text-center">No module found.</td></tr>
				)			 
			}
		  </tbody>

		</table>
		 
		</div>
		</div>
		</div>
		</div>

		</div>
		</div>
		</div>
		</div>
		</div>
</div>
  
    
    
    
    </>
  )

}
export default Modules;
