import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper"; 

export const getRoleslistAction = (data) => {
  return getRequest("getRoleslist", data).then((res) => {
    return res.data;
  });
};

export const getDomainsListAction = (data) => {
  return getRequest("getDomainsList", data).then((res) => {
    return res.data;
  });
};

export const getBranchesListAction = (data) => {
  return getRequest("getBrancheslist", data).then((res) => {
    return res.data;
  });
};
export const getUsersBranchesListAction = (data) => {
  return postRequest("getUserBrancheslist", data).then((res) => {
    return res.data;
  });
};
export const getUsersDomainsListAction = (data) => {
  return postRequest("getUserDomainsList", data).then((res) => {
    return res.data;
  });
};
export const getModulesTypesAction = (data) => {
  return postRequest("getModulesTypes", data).then((res) => {
    return res.data;
  });
};



export const getUsersTopicListAction = (data) => {
  return postRequest("getUserTopicList", data).then((res) => {
    return res.data;
  });
};
export const getCollegesListAction = (data) => {
  return getRequest("getCollegesList", data).then((res) => {
    return res.data;
  });
};
export const getBatchesListAction = (data) => {
  return getRequest("getBatchesList", data).then((res) => {
    return res.data;
  });
};
export const LoginAction = (data) => {
  return postRequest("userLogin", data).then((res) => {
    return res.data;
  });
};
export const RegisterAction = (data) => {
  return postRequest("userRegister", data).then((res) => {
    return res.data;
  });
};
export const getUserDetailsAction = (data) => {
  return postRequest("getUserDetails", data).then((res) => {
    return res.data;
  });
};
export const RegisterGeneralAction = (data) => {
  return postRequestFormData("userRegisterGeneral", data).then((res) => {
    return res.data;
  });
};
export const RegisterEducationAction = (data) => {
  return postRequest("userRegisterEducation", data).then((res) => {
    return res.data;
  });
};
export const RegisterpinAction = (data) => {
  return postRequest("userRegisterPin", data).then((res) => {
    return res.data;
  });
};
export const RegisterprofileAction = (data) => {
  return postRequest("userRegisterProfile", data).then((res) => {
    return res.data;
  });
};
export const validateOtpAction = (data) => {
  return postRequest("validateOtp", data).then((res) => {
    return res.data;
  });
};
export const validateLicAction = (data) => {
  return postRequest("validateLic", data).then((res) => {
    return res.data;
  });
};
export const getUserModulesListAction = (data) => {
  return postRequest("getUserModulesList", data).then((res) => {
    return res.data;
  });
};
export const getUserModuleDetailAction = (data) => {
  return postRequest("getUserModuleDetail", data).then((res) => {
    return res.data;
  });
};
export const askAITrendingAction = (data) => {
  return postRequest("askAITrending", data).then((res) => {
    return res.data;
  });
};
export const askAIAction = (data) => {
  return postRequest("askAI", data).then((res) => {
    return res.data;
  });
};

export const saveUserTimeSpentAction = (data) => {
  return postRequest("saveUserTimeSpent", data).then((res) => {
    return res.data;
  });
};

export const getUserTotalTimeSpentAction = (data) => {
  return postRequest("getUserTotalTimeSpent", data).then((res) => {
    return res.data;
  });
};
export const getgetUserRecentlyReadCourcesAction = (data) => {
  return postRequest("getgetUserRecentlyReadCources", data).then((res) => {
    return res.data;
  });
};
export const getUserModuleDescriptionAction = (data) => {
  return postRequest("getUserModuleDescription", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserCompletionAction = (data) => {
  return postRequest("UpdateUserCompletion", data).then((res) => {
    return res.data;
  });
};

export const getUserCompletedModulePercentageAction = (data) => {
  return postRequest("getUserCompletedModulePercentage", data).then((res) => {
    return res.data;
  });
};

export const getUserModuleAssessmentsAction = (data) => {
  return postRequest("getUserModuleAssessments", data).then((res) => {
    return res.data;
  });
};

export const startUserModuleAssessmentAction = (data) => {
  return postRequest("startUserModuleAssessment", data).then((res) => {
    return res.data;
  });
};

export const getUserAssessmentDetailsAction = (data) => {
  return postRequest("getUserAssessmentDetails", data).then((res) => {
    return res.data;
  });
};

export const SetQuizQuestionAnswerAction = (data) => {
  return postRequest("SetQuizQuestionAnswer", data).then((res) => {
    return res.data;
  });
};

export const getPercentageIndicatorAction = (data) => {
  return postRequest("getPercentageIndicator", data).then((res) => {
    return res.data;
  });
};


export const getUserModulesReportsAction = (data) => {
  return postRequest("getUserModulesReports", data).then((res) => {
    return res.data;
  });
};






