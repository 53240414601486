import React, { useEffect, useState } from 'react'
import { Link, useSearchParams  } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import Cookies from "js-cookie";
import { getUserModulesReportsAction, getUserDetailsAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
import avt from '../assets/rounded.png'
const Reports = () => {
	 const [UserDetails, setusersData] = useState([]);
	 const [UserModulesReports, setUserModulesReports] = useState([]);
	useEffect(() => {
		getProfileAPI();
		getUserModulesReportsAPI();
	},[])
 
	const getProfileAPI = async () => {
		let res = await getUserDetailsAction({ user_id: loginData?.id });
		console.log(res);
		if (res.success) {
			setusersData(res.data);
		}
	};
	
	const getUserModulesReportsAPI = async () => {
		let res = await getUserModulesReportsAction({user_id: loginData?.id});
		if (res.success){      
		  setUserModulesReports(res.data);
		}
		else{
			setUserModulesReports([]);
		}   
   };
  
   
  return (
    <>    
    <div id="main" className="ml-250">
		<div className="dashboar-template">
		 <Sidebar />
		  <div className="dashboar-left">
			<div className="container-fluid">
			  <div className="dashboar-top">
				   <div class="board-heading">
					   <div class="onclick">
						<a href="#" class="openbtn" onclick="openNav()"><img src={`${config.baseUrl}img/dashboard.png`} /></a>
						<div class="on-contant">
						<a href="#">Dashboard</a>
					  </div>
					  </div>      
				  <div class="search">
					<a href="#">> Reports</a>
				  </div>
			</div>
		   <div className="app-header">
			<div className="app-header-left">
			  <div className="setting-item">
				<a href="#">
			  <img src={`${config.baseUrl}img/leaderboard.png`} /></a>
			</div>
			  <div className="setting-item">
				<a href="#">
			  <img src={`${config.baseUrl}img/setting.png`} />
			</a>
			</div>
			<div className="client-post">
			  <a href="#">
			  {UserDetails?.photo ?
				<img src={`${config.UserProfileimageUrl + UserDetails?.photo}`} />
				:
				<img src={`${config.baseUrl}img/rounded.png`} />
				}
			  </a>
			</div>
			<div className="client-heading">
			  <a href="#">{UserDetails?.first_name}</a>
			</div>
			</div>
		</div>
		</div>
		<div className="overview">
		<div className="search-module search-module-main">
			
		<div className="fluid-section search-module-tbl">
		  <div className="topic-table">
			<div className="max-oi">
		  <div className="table-responsive">
		<table className="table table-condensed table-striped table-c  table-reports ">
		<thead>
			<tr>         
			  <th>Module Name</th>
			  <th>Topic</th>
			  <th>Completion Percentage</th>
			  <th>Time Spent</th>
			  <th>Quiz</th>
			</tr>
		</thead>
		<tbody>     
			{ UserModulesReports.length> 0 ?
				UserModulesReports.map((item, index) => (
					<>
						<tr key={index}  className="status">
							
							<td> 
								<Link to={`${config.baseUrl}module/` + item.id}>
								{item.title}
								</Link>
							</td>
							<td>{item.topic_title}</td>
							<td>{item.completion_percentage}%</td>
							<td>{item.timespent_hours}h {item.timespent_minutes}m </td>
							<td>
							  {item.user_module_best_quiz.length > 0 ? item.user_module_best_quiz[0].correct_questions +'/'+ item.user_module_best_quiz[0].total_questions : '-' }
							</td>
													
						</tr>
						</>
					)
				)
				:
				(
					<tr><td colspan="8" className="text-center">No module found.</td></tr>
				)			 
			}
		  </tbody>

		</table>
		 
		</div>
		</div>
		</div>
		</div>

		</div>
		</div>
		</div>
		</div>
		</div>
</div>
  
    
    
    
    </>
  )

}
export default Reports;
