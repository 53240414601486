import React, { useEffect, useState } from 'react'
import { Link, useSearchParams  } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import Cookies from "js-cookie";
import { getUserDetailsAction, getUserTotalTimeSpentAction, getgetUserRecentlyReadCourcesAction, getUserCompletedModulePercentageAction, getPercentageIndicatorAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
import avt from '../assets/rounded.png'
const Dashboard = () => {
	 const [UserDetails, setusersData] 			= useState([]);
	 const [TotalTimeSpent, setTotalTimeSpent] 	= useState({});
	 const [windowWidth, setWindowWidth] 		= useState(window.innerWidth);
	 const [courses, setcourses] 				= useState([]);
	 const [UserDashboardStats, SetUserDashboardStats] 				= useState([]);
	 const [CompletedPer, setCompletedPer] 		= useState(0);
	 const [PercentageIndicator, setPercentageIndicator] 		= useState(0);
	 useEffect(() => {
		getProfileAPI();
		setTabContentHeight();	
		getUserTotalTimeSpentAPI();
		getUserCompletedModulePercentageAPI();
		getUserRecentlyReadCourcesAPI();
		getPercentageIndicatorAPI();
	 },[])
 
  const getProfileAPI = async () => {
		let res = await getUserDetailsAction({ user_id: loginData?.id });
		if (res.success) {
			setusersData(res.data);
		}
	};
  const getUserRecentlyReadCourcesAPI = async () => {
		let res = await getgetUserRecentlyReadCourcesAction({ user_id: loginData?.id });
		if (res.success) {
			setcourses(res.data);
		}
	};
  const getUserCompletedModulePercentageAPI = async () => {
		let res = await getUserCompletedModulePercentageAction({ user_id: loginData?.id });
		if (res.success) {
			setCompletedPer(res.data);
		}
	};
  const getUserTotalTimeSpentAPI = async () => {
		let res = await getUserTotalTimeSpentAction({ user_id: loginData?.id });		
		if (res.success) {			
			setTotalTimeSpent(res.data);
		}
	};	
	
  const getPercentageIndicatorAPI = async () => {
	 	let res = await getPercentageIndicatorAction({ user_id: loginData?.id });		
		if (res.success) {			
			setPercentageIndicator(res.data);
		}
	};	
	
const setTabContentHeight = () => {
	  const bodyHeight = document.body.scrollHeight;
	  const tabContent = document.querySelector('div.overview');
	  const mainContent = document.querySelector('div.projects-file');
	  const rightContent = document.querySelector('div.high-rise');
	  if (tabContent) {
		  if(windowWidth > 1199)
		  {
			  tabContent.style.height = `${bodyHeight - 300}px`;
			  mainContent.style.height = `${bodyHeight - 170}px`;
			  rightContent.style.height = `${bodyHeight - 170}px`;
		   }		
	  }
	};
 

  return (

    <>   
    
    <div id="main" className="ml-250">
<div className="dashboar-template">
 <Sidebar />
  <div className="dashboar-left">
    <div className="container-fluid">
      <div className="dashboar-top">
      <div className="board-heading">
        <div className="on-contant">
          <div className="onclick">
        <a href="#" className="openbtn" onclick="openNav()"><img src={`${config.baseUrl}img/dashboard.png`} /></a>
      </div>
        <a href="#">Dashboard</a>
      </div>
      </div>
   <div className="app-header">
    <div className="app-header-left">
      <div className="setting-item">
        <a href="#">
      <img src={`${config.baseUrl}img/leaderboard.png`} /></a>
    </div>
      <div className="setting-item">
        <a href="#">
      <img src={`${config.baseUrl}img/setting.png`} />
    </a>
    </div>
    <div className="client-post">
      <a href="#">
      {UserDetails?.photo ?
		<img src={`${config.UserProfileimageUrl + UserDetails?.photo}`} />
		:
		<img src={`${config.baseUrl}img/rounded.png`} />
		}
      </a>
    </div>
    <div className="client-heading">
      <a href="#">{UserDetails?.first_name}</a>
    </div>
    </div>
</div>
</div>

<div className="overview">
  <div className="row">
  <div className="col-xl-9">
    <div className="projects-file">
      <div className="overview-heading">
        <h6>General Overview</h6>
      </div>
    <div className="row">
<div className="col-lg-3 col-md-3">
  <div className="percentage shared-style">
    <div className="completed">
    <p>Percentage of
module completed</p>
  </div>
    <div className="super-post">
      <img src={`${config.baseUrl}img/module.png`} />
      <div className="super-heading">
        <h6>{CompletedPer}%</h6>
      </div>
    </div>
</div>
<div className="percentage">
    <div className="completed">
    <p>Percentage
Indicator</p>
  </div>
    <div className="super-post">
      <img src={`${config.baseUrl}img/img2.png`} />
      <div className="super-heading">
        <h6>{PercentageIndicator}%</h6>
      </div>
    </div>
</div>
</div>
<div className="col-lg-9 col-md-9">
  <div className="row">
    <div className="col-lg-7">
      <div className="time-spent">
      <div className="clook-post">
        <img src={`${config.baseUrl}img/total-time.png`} />
      </div>
      <div className="total-text">
        <p>Total Time Spent</p>
        <h6>{TotalTimeSpent.hours}h {TotalTimeSpent.minutes}m</h6>
      </div>
    </div>
  </div>
    <div className="col-lg-5">
      <div className="leval-box">
        <div className="leval-post">
          <img src={`${config.baseUrl}img/your-lavel.png`} />
          <div className="leval-text">
            <p>Your Level</p>
            <h6>23</h6>
          </div>
        </div>
      </div>
    </div>
  </div>


<div className="courses-item">
  <div className="my-courses">
  <h6>My Courses</h6>
</div>
<div className="data-item">
<div className="table-responsive">
<table className="table table-condensed table-striped table-c">
<thead>
    <tr className="strip-bg">         
      <th>Date</th>
      <th>Module Name</th>
      <th>Launch Course</th>
    </tr>
</thead>
<tbody> 
	{ courses.length> 0 ?
				courses.map((item, index) => (
					<>
					 <tr key={index} className="rocket-bg">
							<td>{item.time_spent_date}</td>
							<td>{item.title}</td>
						    <td>
							  <Link to={`${config.baseUrl}module/` + item.id} className="again-btn">
								View Again
								</Link>
							</td>								
						</tr>						
					</>
					)
				)
				:
				(
					<tr><td colspan="3" className="text-center">No module found.</td></tr>
				)			 
			}
    
  </tbody>
</table>
</div>
   
</div>
 <div className="pagination-section d-none">
  
  <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-center align-items-center">
    <li className="page-item ">
      <a className="page-link " href="#" tabindex="-1"></a>
    </li>
    <li className="page-item"><a className="page-link active" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">></a>
    </li>
  </ul>
</nav>
  
  </div>
</div>
 
  
</div>
</div>

</div>
</div>


<div className="col-xl-3">
  <div className="high-rise">
<div className="thi-logo">
<Link to={`https://staging.ediiie.com/7thi/`} target="_blank">
	<img src={`${config.baseUrl}img/7thi-logo.png`} />
</Link>
</div>
<div className="details">
<div className="item-details">
  <div className="icon-bg">
    <img src={`${config.baseUrl}img/right-icon.png`} />
  </div>
  <div className="details-text">
    <p>How can structural integrity
be optimized in a high-rise
building design?</p>
  </div>
</div>
  <div className="adams-btn">
    <p className="authorName">-Kimberly Adams</p>
</div>
  </div>
<div className="details">
<div className="item-details">
  <div className="icon-bg">
    <img src={`${config.baseUrl}img/ster-icon.png`} />
  </div>
  <div className="details-text">
    <p>How can structural integrity
be optimized in a high-rise
building design?</p>
  </div>
</div>
  <div className="adams-btn">
    <p className="authorName">-Kimberly Adams</p>
</div>
  </div>
  <div className="details">
<div className="item-details">
  <div className="icon-bg">
    <img src={`${config.baseUrl}img/flag-icon.png`} />
  </div>
  <div className="details-text">
    <p>How can structural integrity
be optimized in a high-rise
building design?</p>
  </div>
</div>
  <div className="adams-btn">
    <p className="authorName">-Kimberly Adams</p>
</div>
  </div>
  <div className="details">
<div className="item-details">
  <div className="icon-bg">
    <img src={`${config.baseUrl}img/button-icon.png`} />
  </div>
  <div className="details-text">
    <p>How can structural integrity
be optimized in a high-rise
building design?</p>
  </div>
</div>
  <div className="adams-btn">
  <p className="authorName">-Kimberly Adams</p>
</div>
  </div>


</div>




  </div> 
</div>

</div>
</div>
</div>

</div>
</div>
  
    
    
    
    </>
  )

}
export default Dashboard;
