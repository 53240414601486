const config = {
  baseUrl: '/',
 // imageUrl: "http://localhost:3009/images",
  //~ UserProfileimageUrl: "http://localhost:3009/images/userprofile/",
  UserProfileimageUrl: "https://staging.ixrlabs.com/apis/api/uploads/userprofile/",
  //~ apiUrl: "http://localhost:3009/api",
  imageUrl: "https://staging.ixrlabs.com/apis/api/uploads/",
  apiUrl: "https://staging.ixrlabs.com/apis/api",
  superAdmin:1,
  STUDENT_ROLE_ID:5,
  searchTerm:'Please ask a question within the scope',
  paging_size:50
}
export default config;
