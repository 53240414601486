import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { getUserDetailsAction,RegisterpinAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
	
const Registerpin = () => {
	
	const [UserDetails, setUserDetails] = useState({});	
	const [form, setForm] = useState({ user_id: '',username: '', user_pin: '' });
	const [validationError, setvalidationError] = useState({});
	const [Passwordtype, setPasswordType] = useState('password');
	const [Passwordicon, setPasswordIcon] = useState(config.baseUrl+'img/eyes.png');
	
	const handlePasswordToggle = () => {
	   if (Passwordtype==='password'){
		  setPasswordIcon(config.baseUrl+'img/eye.png');
		  setPasswordType('text')
	   } else {
		  setPasswordIcon(config.baseUrl+'img/eyes.png');
		  setPasswordType('password')
	   }
	}
	const check_valid_stage = async () => {		
		if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		}
		else
		{
			let cookie_user_id	=	loginData.id;		
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });		  
			if (res.success) {				
				setUserDetails(res.data);			  						
				let data = res.data;				
				setForm((old) => {
					return { ...old, user_id: data.id, username: data.username, user_pin: data.user_pin };
				});
			}		
		}    
    } 
    
	useEffect(() => {
		check_valid_stage();
	}, []);
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const handleKeyPress = (e) => {
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  

	function validate() {
		let usernameError = "";
		let user_pinError = "";
		
		if (form.username === '' || form.username === null) {
			usernameError = "Username is required."
		}
		if (form.user_pin === '' || form.user_pin === null) {
			user_pinError = "Pin is required."
		}
		
		if (usernameError || user_pinError) {
			setvalidationError({
			  usernameError, user_pinError
			})
			return false
		} else {
			return true
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {			
			
			let res = await RegisterpinAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}registerprofile`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
			
		}
	}

	const SkipForm = async (e) => {				
		let res = await RegisterpinAction({user_id:loginData.id,username:'',user_pin:''});
		if (res.success) {
			toast.success(res.msg);
			setTimeout(() => {
				window.location.href = `${config.baseUrl}registerprofile`;
			}, 1200);
		} else {
			toast.error(res.msg);
		}
			
	}
	
	
	
	
	



  return (
  
    <>
    
	<div className="dasbord">
		<Toaster /> 
		
		<div className="container">
		<div className="dashboard-menu">
		<div className="row">
			<div className="col-lg-6">
				<div className="laft-post">
					<div className="iXR-new">
						<img src={`${config.baseUrl}img/iXRlabs.png`} />
					</div>
					<div className="engine">
						<img src={`${config.baseUrl}img/heart-image.png`}  />
					</div>
				</div>
				
			</div>
			<div className="col-lg-6">
			<div className="password-field">
			  
			  <div className="registration-main">
					<div className="range-item">
						<ul className="list-inline">
						  <li className="list-item active">
							<span>Account</span>
						  </li>
						  <li className="list-item active">
							<span>General</span>
						  </li>
						  <li className="list-item active">
							<span>Education</span>
						  </li>
						  <li className="list-item active">
							<span>Pin</span>
						  </li>
						  <li className="list-item">
							<span>Profile</span>
						  </li>
						  <li className="list-item ">
							<span>Finish</span>
						  </li>
						</ul>
					 
					  </div>
				  </div>
						  
		<div className="account-main">
		<div className="row align-items-center">
			<div className="col-md-9">

				<div className="Registration">
					  <h2>Account Pin Setup</h2>
					  <div className="accessin-text">
						<p>This is required for accessing VR app, if you skip the step, you can access your account by email and password setup previously</p>
					  </div>
					</div>
				 
			</div>
			<div className="col-md-3">
				<div className="skip-btn">
				  <a href="javascript:;" onClick={SkipForm}>Skip</a>
				</div>
			</div>
		</div>
      
        <div className="form-section">
			<form>
				<div className="form-group">
					<label>Username</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/user.png`} /></span>
						<input className={validationError.usernameError ? 'form-control is-invalid' : 'form-control'} type="text" onChange={inputHandler} name="username" value={form.username}  placeholder="Enter Username"  />
					</div>
					<p className="set-text">Use a unique name, see suggestion. Or use email/mobile number</p>
					<span className="invalid-feedback">{validationError.emailError}</span>
				</div>
				<div className="form-group">
					<label>User PIN</label>
					<div className="icon-block">
						<span className="lock-icon"><img src={`${config.baseUrl}img/lock.png`} /></span>
						<input className={validationError.user_pinError ? 'form-control is-invalid' : 'form-control'} type={Passwordtype} onChange={inputHandler} name="user_pin" value={form.user_pin}  placeholder="Enter Your PIN"  />
						<span className="span-right"  onClick={handlePasswordToggle} >
						<img src={Passwordicon} />
						</span>
						<p className="set-text">use a 5 digit pin to set your login</p>
					</div>
					<span className="invalid-feedback">{validationError.emailError}</span>
				</div>
		</form>
		 
		 
		 <div className="main-item-btn">
		  <div className="previous-btn d-none">
			<a href={`${config.baseUrl}registereducation`} className="login-btn previous">
			<span><i className="fa-solid fa-arrow-left"></i></span>Previous
			</a>

		</div>
		<div className="solid-btn">
			<button className="login-btn btn-bg"  type="submit" onClick={SubmitForm}  >Next<span><i className="fa-solid fa-arrow-right"></i></span></button>
		</div>
	</div>

 
</div>
</div>
</div>
</div>
</div>
</div>
</div>

	</div>

    </>
  )

}
export default Registerpin;
