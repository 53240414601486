import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import Cookies from "js-cookie";
import toast, { Toaster } from 'react-hot-toast';
import { getUserDetailsAction, getUserModuleDetailAction,askAIAction, askAITrendingAction, saveUserTimeSpentAction, getUserModuleDescriptionAction,UpdateUserCompletionAction,getUserModuleAssessmentsAction,startUserModuleAssessmentAction,getUserAssessmentDetailsAction,SetQuizQuestionAnswerAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
import avt from '../assets/rounded.png'
import loader from '../assets/loader.gif'
import minmizeICO from '../assets/minimize.png'
import MaximizeICO from '../assets/expand.png'

const Module = () => {
	 const { id } = useParams();	 
	 const [entryTime, setEntryTime] = useState(new Date());
	 const [UserDetails, setusersData] = useState([]);
	 const [ModuleDetail, setModuleDetail] = useState([]);
	 const [ModuleDescription, setModuleDescription] = useState([]);
	 const [AIQuestionAnswers, setAIQuestionAnswers] = useState([]);
	 const [ModuleWebAssetDetail, setModuleWebAssetDetail] = useState([]);
	 const [activeTab, setActiveTab] = useState(0);
	 const [innderTab, setinnderTab] = useState(0);
	 const [spinLoader, setSpinLoader] = useState(0);
	 const [isFullscreen, setisFullscreen] = useState(0);
	 const [titleFilter, SettitleFilter] = useState('');
	 const [domainFilter, SetdomainFilter] = useState('');
	 const [branchFilter, SetbranchFilter] = useState('');
	 const [topicFilter, SettopicFilter] = useState('');	
	 const [askQuestion, SetaskQuestion] = useState('');		
	 const [Simulation, SetSimulation] = useState('');	
	 const [substr, Setsubstr] = useState('');		
	 const [questionAnswers, setQuestionAnswers] = useState([]);
	 const [UserModuleAssessments, setUserModuleAssessments] = useState([]);
	 const [UserAssessmentDetails, setUserAssessmentDetails] = useState([]);
	 
	 const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	 const [selectedAnswerIds, setSelectedAnswerIds] = useState({});
	 const [QuizThanksMsg, setQuizThanksMsg] = useState(0);
	 const [QuizReviewMode, setQuizReviewMode] = useState(0);

	 
	 useEffect(() => {
		setEntryTime(new Date());
		getProfileAPI();
		getModuleDetailAPI(); 
		getModuleDescriptionsAPI(); 
		UserModuleAssessmentsAPI(); 
		setTabContentHeight();
		sendTimeSpent();	
		const interval = setInterval(sendTimeSpent, 30000);
		// Clean up the interval on component unmount
		return () => clearInterval(interval);
	 },[])
	 
  const sendTimeSpent = async () => {
		const exitTime = new Date();
		const timeSpent = parseInt(exitTime - entryTime)/1000; // Calculate time spent in milliseconds
		if(timeSpent > 30)
		{
			await saveUserTimeSpentAction({ user_id: loginData?.id,timeSpent : 30, module_id : id, date:exitTime  });	
		}
		
		
	  }; 
   const toggleFullScreen = async () => {
    const iframe = document.getElementById('simulation');
    console.log(iframe);
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
      setisFullscreen(1);
    } else if (iframe.mozRequestFullScreen) { /* Firefox */
      iframe.mozRequestFullScreen();
      setisFullscreen(1);
    } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      iframe.webkitRequestFullscreen();
      setisFullscreen(1);
    } else if (iframe.msRequestFullscreen) { /* IE/Edge */
      iframe.msRequestFullscreen();
      setisFullscreen(1);
    }
    else
    {
		setisFullscreen(0);
	}
  };
  const getProfileAPI = async () => {
		let res = await getUserDetailsAction({ user_id: loginData?.id });
		if (res.success) {
			setusersData(res.data);
		}
	};
  const getModuleDetailAPI = async () => {
	   setSpinLoader(1)
		let res = await getUserModuleDetailAction({ id: id });
		if (res.success) {
			setModuleDetail(res.data);
			SettitleFilter(res.data.title);
			let qst = 'Explain about '+ res.data.title +'?.'			
			SetbranchFilter(res.data.branch_title);
			SetdomainFilter(res.data.domain_title);
			SettopicFilter(res.data.topic_title);
			setModuleWebAssetDetail(res.webassets);
			
			let res1 = await askAIAction({title:res.data.title, domain:res.data.domain_title, branch:res.data.branch_title, topic:res.data.topic_title,question:qst});
			if (res1.success) {							
				appendQuestionAnswer(qst,res1.data);								
				let rel = await askAITrendingAction({title:titleFilter, domain:domainFilter, branch:branchFilter, topic:topicFilter,question:qst});   
				if (rel.success) {
					setAIQuestionAnswers(rel.data); 	
					setSpinLoader(0)
				}
				        
			}
						
		}
	};
  const getModuleDescriptionsAPI = async () => {
	   setSpinLoader(1)
		let res = await getUserModuleDescriptionAction({ id: id });
		if (res.success) {
			setModuleDescription(res.data);		
			if(res.data.length > 5){
				Setsubstr(10);
			}
			else {
				Setsubstr(15);
			}
			setSpinLoader(0)			
		}
	};
  const UserModuleAssessmentsAPI = async () => {
	   setSpinLoader(1)
		let res = await getUserModuleAssessmentsAction({ module_id: id,user_id: loginData?.id });
		if (res.success) {
			setUserModuleAssessments(res.data);		
			if(res.data.length > 5){
				Setsubstr(10);
			}
			else {
				Setsubstr(15);
			}
			setSpinLoader(0)			
		}
	};
  const startUserModuleAssessmentAPI = async () => {
	   setSpinLoader(1)
		let res = await startUserModuleAssessmentAction({ module_id: id,user_id: loginData?.id });
		if (res.success) {
			setUserAssessmentDetails(res.data);		
			setQuizReviewMode(0);		
			if(res.data.length > 5){
				Setsubstr(10);
			}
			else {
				Setsubstr(15);
			}
			setSpinLoader(0)			
		}
	};
  const UserAssessmentDetailsAPI = async (quiz_id) => {
	   setSpinLoader(1)
		let res = await getUserAssessmentDetailsAction({ module_id: id,user_id: loginData?.id,quiz_id: quiz_id });
		if (res.success) {
			setUserAssessmentDetails(res.data);	
			setQuizReviewMode(1);	
			if(res.data.length > 5){
				Setsubstr(10);
			}
			else {
				Setsubstr(15);
			}
			setSpinLoader(0)			
		}
	};
	
  
	
	const goToPreviousQuestion = () => {
		setCurrentQuestionIndex(prevIndex =>
		  Math.max(0, prevIndex - 1)
		);
	  };

	const goToNextQuestion = () => {
		setCurrentQuestionIndex(prevIndex =>
		  Math.min(UserAssessmentDetails[0].questions.length - 1, prevIndex + 1)
		);
		
		let questions_length	=	UserAssessmentDetails[0].questions.length - 1;
		
		if(questions_length==currentQuestionIndex && QuizReviewMode==0)
		{
			setQuizThanksMsg(1);
		}
		else
		{
			setQuizThanksMsg(0);
		}
		
		
	};
	
	const handleExitQuiz = () => {
		setQuizThanksMsg(0);
		setUserAssessmentDetails({});
		UserModuleAssessmentsAPI();
		setCurrentQuestionIndex(0);
		setSelectedAnswerIds({});
	}
	
	const handleOptionClick = (questionIndex, answerId) => {
		setSelectedAnswerIds({
		  ...selectedAnswerIds,
		  [questionIndex]: answerId,
		});
  };
  
  
  const handleSetQuizQuestionAnswer = async (next_prev,quiz_id,quiz_question_id,questionIndex) => {
	   setSpinLoader(1)
	   
	   let 	question_selected_opt_id	=	selectedAnswerIds[questionIndex] || 0;
	   
	   let res = await SetQuizQuestionAnswerAction({ module_id: id,user_id: loginData?.id,quiz_id: quiz_id,quiz_question_id: quiz_question_id,question_selected_opt_id: question_selected_opt_id });
		if (res.success) {
			
			if(next_prev=="next")
			{
				goToNextQuestion();	
			}
			else if(next_prev=="prev")
			{
				goToPreviousQuestion();	
			}
				
			if(res.data.length > 5){
				Setsubstr(10);
			}
			else {
				Setsubstr(15);
			}
			setSpinLoader(0)			
		}
	};
  
	
  const handleTabClick = (index) => {
		setActiveTab(index);
		if(index==2)
		{
			SetSimulation("https://ixr-assets.s3.ap-south-1.amazonaws.com/webgl/ConeCrusher/index.html");
		}
		if(index==1)
		{
			setinnderTab(0);
		}
	};
  const handleInnderTabClick = async (index,desc_id) => {
		setinnderTab(index);
		if(index != 0)
		{
			let res = await UpdateUserCompletionAction({ module_id: id,desc_id:desc_id });
		}
	};
  const inputQuestionHandler = async (e) => {
		SetaskQuestion(e.target.value);
		
	}
  const handleSubmit = (event) => {
    if (event.key === 'Enter') {
		event.preventDefault(); 
        ChatWithAI();
    }
  };
  const setTabContentHeight = () => {
	  const bodyHeight = document.body.scrollHeight;
	  const tabContent = document.querySelector('div.tab-cont');
	  if (tabContent) {
		tabContent.style.height = `${bodyHeight - 300}px`;
	  }
	};
  const appendQuestionAnswer = (question, answer) => {	 
	  const newQa = { question, answer };	 
	  setQuestionAnswers(prevState => [...prevState, newQa]);
	};
  const ChatWithAI = async () => {	 
	 setSpinLoader(1)
		if(askQuestion !='')
		{	
				
			let res = await askAIAction({title:titleFilter, domain:domainFilter, branch:branchFilter, topic:topicFilter,question:askQuestion});
			if (res.success) {							
				appendQuestionAnswer(askQuestion,res.data);	
				if(!res.data.includes(config.searchTerm)){				   									
					let rel = await askAITrendingAction({title:titleFilter, domain:domainFilter, branch:branchFilter, topic:topicFilter,question:askQuestion});   
					if (rel.success) {
						setAIQuestionAnswers(rel.data); 
						setSpinLoader(0)
						SetaskQuestion('');	
					}
				}else{
					setSpinLoader(0)
					SetaskQuestion('');	
				}				      
			}
			
		}
		else
		{
			toast.error('Please ask a question.')
		}
    }
  useEffect(() => {
    return sendTimeSpent;
  }, []);
  
 const handleClick = async (event) => {
	setSpinLoader(1)
    const question = event.target.textContent;
    let newquestion = question.trim();
    if (/^\d+\./.test(newquestion.substring(0, 2))) {     
      newquestion = newquestion.substring(2).trim();
    }
    SetaskQuestion(newquestion);
	let res = await askAIAction({title:titleFilter, domain:domainFilter, branch:branchFilter, topic:topicFilter,question:newquestion});
		if (res.success) {							
			appendQuestionAnswer(newquestion,res.data);								
			let rel = await askAITrendingAction({title:titleFilter, domain:domainFilter, branch:branchFilter, topic:topicFilter,question:newquestion});   
			if (rel.success) {
				setAIQuestionAnswers(rel.data);
				setSpinLoader(0) 
				SetaskQuestion('');	
			}				      
		}
			
  }
  
  const handleFocus = async (event) =>  {
    event.target.value = ''; // Clear the value of the textbox
    SetaskQuestion('');
  }
    
  return (
    <>     
    <div id="main" className="ml-250">
<div className="dashboar-template">
 <Sidebar />
  <div className="dashboar-left">
    <div className="container-fluid">
      <div className="dashboar-top">
      <div className="board-heading">
           <div className="onclick">
			<a href="#" className="openbtn" onclick="openNav()">
				<img src={`${config.baseUrl}img/dashboard.png`} /></a>
			<div className="on-contant">
			<a href="#">Dashboard</a>
		  </div>
		  </div>      
      <div className="search">
      	<a href="#">> {ModuleDetail?.title}</a>
      </div>
      </div>
   <div className="app-header">
    <div className="app-header-left">
      <div className="setting-item">
        <a href="#">
      <img src={`${config.baseUrl}img/leaderboard.png`} /></a>
    </div>
      <div className="setting-item">
        <a href="#">
      <img src={`${config.baseUrl}img/setting.png`} />
    </a>
    </div>
    <div className="client-post">
      <a href="#">
      {UserDetails?.photo ?
		<img src={`${config.UserProfileimageUrl + UserDetails?.photo}`} />
		:
		<img src={`${config.baseUrl}img/rounded.png`} />
		}
      </a>
    </div>
    <div className="client-heading">
      <a href="#">{UserDetails?.first_name}</a>
    </div>
    </div>
</div>
</div>

<div className="engine-work">
<div className="private-section">
    <div className="workcover">
<div className="tab-block" id = "tab-block">
  <ul className="tab-mnu">
    <li className={activeTab === 0 ? 'active' : ''} onClick={() => handleTabClick(0)}><span className="assesment"><img src={`${config.baseUrl}img/7thi.png`} /></span>7Thi</li>
    <li className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}><span className="assesment"><img src={`${config.baseUrl}img/academic-Icon.png`} /></span>Academic </li>
    <li className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}><span className="assesment"><img src={`${config.baseUrl}img/simulation.png`}  /></span>Simulation</li>
    <li className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabClick(3)}><span className="assesment"><img src={`${config.baseUrl}img/assesment.png`}  /></span>Assesment</li>
  </ul>
  <div className="tab-cont">
    <div className="tab-pane" style={activeTab === 0 ? { display: 'block' } : { display: 'none' }}>
      <div className="medicare">
			 <div className="how">			
				{questionAnswers.map((qa, index) => (
					  <div key={index}>
						<div className="does-heading">
						  <h6>You</h6>
						  <div className="does-text">
							<p>{qa.question}</p>
						  </div>
						</div>
						<div className="high-heading">
						  <h6>7thi</h6>				
						  <div className="high-speed">
							<p>{qa.answer}</p>
						  </div>
						</div>
					  </div>
					))}
				 
			
			 <div className="thumb-down d-none">
				 <a href="#"><img src={`${config.baseUrl}img/copy.png`} /></a> 
				 <a href="#"><img src={`${config.baseUrl}img/thumbtop.png`} /></a> 
				 <a href="#"><img src={`${config.baseUrl}img/thumb-down.png`} /></a> </div>					
			{AIQuestionAnswers && AIQuestionAnswers.length > 0 ?
				(
					<div className="components">
						<div className="fuel-item">
							{AIQuestionAnswers.map((question, index) => (
								<div key={index} className="developing">                          
									<p onClick={handleClick}>{question}</p>
								</div>
							))}
						</div>
					</div>
					
				)
				:
				(
					<div className="loaderDiv">
						<img className='loaderImg' src={loader} height={50} width={50} />
					</div>
				)
			}
			

			 <div className="textarea-heading">
				  <textarea className="form-control" id="askAI" onChange={inputQuestionHandler} onKeyDown={handleSubmit}  placeholder="Ask 7thi..." value={askQuestion} onFocus={handleFocus}></textarea>
				  <div className="threee-icon"> <a disabled={spinLoader==1 ? true : false}  className="arrow-up button" href="javascript:;" onClick={() => { ChatWithAI() }}>
				  {spinLoader == 1 ? <img className='loaderImg' src={loader} height={16} width={16} /> : <img src={`${config.baseUrl}img/chat-icon.png`} />} </a>
				   </div>
			</div>  
		  </div>
        </div>                 
    </div>
	<div className="tab-pane" style={activeTab === 1 ? { display: 'block' } : { display: 'none' }}>	
	<div className="tab-block" id = "tab-block-inner">
		  <ul className="tab-mnu">
			<li className={innderTab === 0 ? 'active' : ''} c>Description</li>
			{ ModuleDescription.length> 0 ?
				ModuleDescription.map((item, index) => (
					<>
						<li className={innderTab === index+1 ? 'active' : ''} onClick={() => handleInnderTabClick(index+1,item.id)}>
						{item.title.length > substr ? item.title.substring(0, substr) + "..." : item.title}</li>
					</>
					)
				)
				: ''
			}
		  </ul>
	  <div className="tab-cont">
		<div className="tab-pane" style={innderTab === 0 ? { display: 'block' } : { display: 'none' }}>     
			<div className="lights-box">
				<div className="compress-text">
					<div dangerouslySetInnerHTML={{ __html: ModuleDetail?.web_content }} ></div>
			  </div>
		 </div>             
		</div>
		{ ModuleDescription.length> 0 ?
				ModuleDescription.map((item, index) => (
					<>
						<div className="tab-pane" style={innderTab === index+1 ? { display: 'block' } : { display: 'none' }}>
							  <div className="lights-box">
								  <h2>{item.title} </h2>
									<div className="compress-text">
										<div dangerouslySetInnerHTML={{ __html: item?.description }} ></div>
								  </div>
							 </div>
						 </div>
					</>
					)
				)
				: ''
			}
	</div>
	</div>
	</div>
	<div className="tab-pane" style={activeTab === 2 ? { display: 'block' } : { display: 'none' }}>
		  <div className="lights-box">
			<div className="compress-text div-rel">
				{ ModuleWebAssetDetail.index_file_url !='' ?
					<>
					 <iframe
						src={Simulation}
						title="HTML File"
						width="100%"
						height="500px"
						frameBorder="0"
						allowFullScreen
						id="simulation"
					  ></iframe>
					  { isFullscreen==0 ?
						   <span className="window-size" onClick={toggleFullScreen}><img src={`${config.baseUrl}img/expand.png`} /></span>	
						   :
						    <span className="window-size" onClick={toggleFullScreen}><img src={`${config.baseUrl}img/minimize.png`}  height={17} width={17} /></span>	
						}	
					 				
					 </>
					 : ''
				 }
			</div>
		   

		  </div>
	 </div>
	<div className="tab-pane" style={activeTab === 3 ? { display: 'block' } : { display: 'none' }}>
		  <div className="lights-box">
			<div className="compress-text">
			  {QuizThanksMsg > 0 ? (
				<div className="any-question">
				  <div className="demo-area text-center">
					<h3>Thank you!!</h3>
				  </div>
				  <div className="summit"><a href="javascript:void(0);" onClick={() => handleExitQuiz()}>Go to results!</a></div>
				</div>
			  ) : (
				UserAssessmentDetails.length > 0 ? (
				  UserAssessmentDetails.map((quiz, quizIndex) => (
					quiz.questions.map((question, questionIndex) => (
					  <div className="any-question" style={{ display: questionIndex === currentQuestionIndex ? 'block' : 'none' }} >
						<div className="small-hint">
						  <div className="overview-heading text-question">
							<h6>Question {question.display_order}/{quiz.total_questions}</h6>
						  </div>
						  
							  
						  {QuizReviewMode > 0 ? (
								<div className="hint-text">
									{questionIndex > 0 && (
										<a className="dark-black" href="javascript:void(0);" onClick={() => goToPreviousQuestion()}>Previous</a>
									)}
									{questionIndex < quiz.questions.length - 1 && (
										<a className="dark-grey" href="javascript:void(0);" onClick={() => goToNextQuestion()}>Next</a>
									)}
								</div>
							)
							:
							(
								<div className="hint-text">
									{questionIndex > 0 && (
										<a className="dark-black" href="javascript:void(0);" onClick={() => handleSetQuizQuestionAnswer('prev', quiz.id, question.id, questionIndex)}>Previous</a>
									)}
									{questionIndex < quiz.questions.length - 1 && (
										<a className="dark-grey" href="javascript:void(0);" onClick={() => handleSetQuizQuestionAnswer('next', quiz.id, question.id, questionIndex)}>Next</a>
									)}
								</div>
							)
							}
							
							
						</div>
						<div className="demo-area">
						  <div dangerouslySetInnerHTML={{ __html: question?.title }}></div>
						</div>
						
						{QuizReviewMode > 0 ? (
							
						  <div>
							  <div className="consectetuec">
								{question.question_distractors.map((q_d, index) => (
								  <div className={`box-demo ${((question.is_correct === 1 && question.is_attempted === 1 && q_d.id === question.selected_option) || (q_d.is_correct === 1 && question.is_attempted === 1)) ? 'correctans' : (question.is_correct === 0 && question.is_attempted === 1 && q_d.id === question.selected_option) ? 'incorrectans' : ''}`}>
									<div className="plus-icon">
									  <span>
										{((question.is_correct === 1 && question.is_attempted === 1 && q_d.id === question.selected_option) || (q_d.is_correct === 1 && question.is_attempted === 1)) ? (
										  <img src={`${config.baseUrl}img/right-tick.png`} />
										) : question.is_correct === 0 && question.is_attempted === 1 && q_d.id === question.selected_option ? (
										  <img src={`${config.baseUrl}img/Plus.png`} />
										) : null}
									  </span>
									</div>
									<div className="demo-info">
									  <div dangerouslySetInnerHTML={{ __html: q_d?.distractor_title }}></div>
									</div>
								  </div>
								))}
							  </div>
							  <div class="correct-answer">
							  <div class="text-answer">
								  {question.question_distractors.map((q_d, index) => (
								  q_d.is_correct === 1 ? (
								  
										<p>Correct Answer : {q_d.distractor_title.replace(/<[^>]*>?/gm, '')}</p>
										
									  ) : null
								  ))}

							  </div>
							  <div class="Explanation">
								
								<div class="correct">
								  <p>Explanation:</p>
								</div>							  
							 
								<div class="sit-amet">
								  <div dangerouslySetInnerHTML={{ __html: question?.solution }}></div>
								</div>
							  </div>
							</div>

							  <div className="summit"><a href="javascript:void(0);" onClick={() => handleExitQuiz()}>Go to results!</a></div>						
							</div>	
						) : (
							<div>
								<div className="nonummy-section quiz-distrs-section">
								{question.question_distractors.map((q_d, index) => (
								  <div className="group-item" onClick={() => handleOptionClick(questionIndex, q_d.id)}>
									<div className={`group-contant ${selectedAnswerIds[questionIndex] === q_d.id ? 'active' : ''}`}>
									 
									  <div className="right-tick">
										<span href="javascript:void(0);">
										  <img src={`${config.baseUrl}img/right-tick.png`} className={`${selectedAnswerIds[questionIndex] !== q_d.id ? 'd-none' : ''}`} alt="tick icon" />
										</span>
										<div className="group-text">
										  <div dangerouslySetInnerHTML={{ __html: q_d?.distractor_title }}></div>
										</div>
									  </div>
									  </div>
								
								  </div>
								))}
							  </div>
								
								<div className="summit"><a href="javascript:void(0);" onClick={() => handleSetQuizQuestionAnswer('next', quiz.id, question.id, questionIndex)}>SUBMIT</a></div>
							</div>	
						)}
						
					 </div>
					))
				  ))
				) : (
				  <div className="courses-item">
					<div className="my-assessments">
					  <h6>My Assessments</h6>
					  <Link to="#" className="start-quiz-btn" onClick={startUserModuleAssessmentAPI}>Start Quiz</Link>
					</div>
					<div className="data-item">
					  <div className="table-responsive">
						<table className="table table-condensed table-striped table-c table-my-assessments">
						  <thead>
							<tr className="strip-bg">
							  <th>Date</th>
							  <th>Total Questions</th>
							  <th>Attempted Questions</th>
							  <th>Correct Questions</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
							{UserModuleAssessments.length > 0 ? (
							  UserModuleAssessments.map((assessment, index) => (
								<tr className="rocket-bg">
								  <td>{assessment.assessment_date}</td>
								  <td>{assessment.total_questions}</td>
								  <td>{assessment.total_questions_attempted}</td>
								  <td>{assessment.correct_questions}</td>
								  <td>
									<a className="again-btn" href="javascript:;" onClick={() => UserAssessmentDetailsAPI(assessment.id)}>
									  View Again
									</a>
								  </td>
								</tr>
							  ))
							) : (
							  <tr><td colSpan="5" className="text-center">No assessment found.</td></tr>
							)}
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				)
			  )}
			</div>

		  
		  </div>
	 </div>
	
</div>
</div>
</div>
</div>



</div>
</div>

</div>
</div>
  </div>
    
    </>
  )

}
export default Module;
