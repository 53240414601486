import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { getUserDetailsAction,RegistercongratsAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Registercongrats = () => {
	
	const [UserDetails, setUserDetails] = useState({});
	
	const [form, setForm] = useState({ user_id: '',username: '', user_pin: '' });
	const [validationError, setvalidationError] = useState({});
	const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
	
			
	const check_valid_stage = async () => {
		
		if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		}
		else
		{
			let cookie_user_id	=	loginData.id;
		
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
		  
			if (res.success) {
				
				setUserDetails(res.data);
			}
		
		} 
   
    } 
    
	useEffect(() => {
		check_valid_stage();
	}, []);
	
	

	const SubmitForm = async (e) => {
		
	window.location.href = `${config.baseUrl}dashboard`;
				
	}

	

  return (
  
    <>
    
	<div className="dasbord">
		<Toaster /> 
		
		<div className="container">
		<div className="dashboard-menu">
		<div className="row">
		<div className="col-lg-6">
						<div className="laft-post">
							<div className="iXR-new">
								<img src={`${config.baseUrl}img/iXRlabs.png`} />
							</div>
							<div className="engine">
								<img src={`${config.baseUrl}img/heart-image.png`}  />
							</div>
						</div>
						
					</div>
			<div className="col-lg-6">
				<div className="password-field">
				  
				  <div className="registration-main">
					<div className="range-item">
						<ul className="list-inline">
						  <li className="list-item active">
							<span>Account</span>
						  </li>
						  <li className="list-item active">
							<span>General</span>
						  </li>
						  <li className="list-item active">
							<span>Education</span>
						  </li>
						  <li className="list-item active">
							<span>Pin</span>
						  </li>
						  <li className="list-item active">
							<span>Profile</span>
						  </li>
						  <li className="list-item active">
							<span>Finish</span>
						  </li>
						</ul>
					 
					  </div>
				  </div>
						  
			 <div className="account-main">
				  <div className="congrats-item">
					<div className="finish-post">
						<img src={`${config.baseUrl}img/congrats.png`} />
					</div>
					<div className="heading-grats">
						<h2>Congrats!</h2>
						<p>You've successfully set up your account</p>
						<div className="continue-btn">
							<a href="javascript:;" onClick={SubmitForm}>Continue</a>
						</div>
					</div>      
				</div>

</div>
</div>
</div>
</div>
</div>
</div>

    </div>
    
    </>
  )

}
export default Registercongrats;
