import React, { Component, useEffect, useState } from 'react'
import { getUserDetailsAction } from '../Action/action';
import config from '../coreFIles/config'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));

const Header = () => {
  if (!loginData || loginData == '') {
    window.location.href = `${config.baseUrl}`;
  }
  else
  {
	  //~ let cookie_user_id	=	loginData.id;		 
	//~ let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
	//~ if (res.success) {				
		//~ if(res.data.reg_stage==1)
		//~ {
			//~ window.location.href = `${config.baseUrl}registergeneral`;
		//~ }
		//~ else if(res.data.reg_stage==2)
		//~ {
			//~ window.location.href = `${config.baseUrl}registereducation`;
		//~ }
		//~ else if(res.data.reg_stage==3)
		//~ {
			//~ window.location.href = `${config.baseUrl}registerpin`;
		//~ }
		
	//~ }
  }

  const logout = async () => {
    Cookies.remove('loginSuccessIXRFront');
    window.location.href = config.baseUrl;
  }
  

 return (

    <>    
    
    
      
    
    </>
  )
}
export default Header;
