import React, { useEffect, useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
import { getUserDetailsAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessIXRFront')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRFront'));
const Sidebar = () => { 
	 useEffect(() => {
		  if (!loginData || loginData == '') {
			window.location.href = `${config.baseUrl}`;
		  }
		  else
		  {
			  getProfileAPI();
		  }
   },[])
  
   const getProfileAPI = async () => {
		let cookie_user_id	=	loginData.id;		 
			let res = await getUserDetailsAction({ 'user_id': cookie_user_id });
			if (res.success) {				
				if(res.data.reg_stage==1)
				{
					window.location.href = `${config.baseUrl}registergeneral`;
				}
				else if(res.data.reg_stage==2)
				{
					window.location.href = `${config.baseUrl}registereducation`;
				}
				else if(res.data.reg_stage==3)
				{
					window.location.href = `${config.baseUrl}registerpin`;
				}
				
			}
	};	
	
  const [pageUrl, setPageUrl] = useState(window.location.href);
  const [isToggled, setIsToggled] = useState(false);

  const logout = async () => {
    Cookies.remove("loginSuccessIXRFront");
    window.location.href = config.baseUrl;
  };
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
	
  return (
    <>    
    
    
    <div id="mySidebar" className="sidebar width-250">
		<div className="dashboar-right">
		  <div className="dashboar-logo">
			<img src={`${config.baseUrl}img/iXRlabs.png`} />
		  </div>
		  <div className="sidebar-left">			  
			  
			<div class={pageUrl.match('/dashboard') ? 'modules active' : 'modules'} >
				<a href={`${config.baseUrl}dashboard`} > <span><img src={`${config.baseUrl}img/home1.png`} /></span>Home</a>
			</div>
			  
			<div class={pageUrl.match('/module') ? 'modules active' : 'modules'} >
				<a href={`${config.baseUrl}modules`} > <span><img src={`${config.baseUrl}img/modules.png`} /></span>Modules</a>
			</div>
			  
			<div class={pageUrl.match('/knowledge-map') ? 'modules active' : 'modules'} >
				<a href="#" > <span><img src={`${config.baseUrl}img/knowledge-map.png`} /></span>Knowledge Map</a>
			</div>
			  
			<div class={pageUrl.match('/ask-ai') ? 'modules active' : 'modules'} >
				<a href="#" > <span><img src={`${config.baseUrl}img/ask-ai.png`} /></span>Ask AI</a>
			</div>
			  
			<div class={pageUrl.match('/social-wall') ? 'modules active' : 'modules'} >
				<a href="#" > <span><img src={`${config.baseUrl}img/social-wall.png`} /></span>Social Wall</a>
			</div>
			  
			  
			<div class={pageUrl.match('/reports') ? 'modules active' : 'modules'} >
				<a href={`${config.baseUrl}reports`} > <span><img src={`${config.baseUrl}img/get-reports.png`} /></span>Get Reports</a>
			</div>
			  
			  
		  </div>
		  <div className="fire-btn">
			<span className="logout-post"><a href="javascript:;" onClick={logout}><img src={`${config.baseUrl}img/log-out.png`} /></a></span>
			<a className="out-now" href="javascript:;" onClick={logout} >Log out</a>
		  </div>
		</div>
		</div>
    
    
    
    
    
    </>
  );
};
export default Sidebar;
