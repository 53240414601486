import './App.css';
import config from "./coreFIles/config";
import Login from './component/login';
import Register from "./component/register";
import Registergeneral from "./component/registergeneral";
import Registereducation from "./component/registereducation";
import Registerpin from "./component/registerpin";
import Registerprofile from "./component/registerprofile";
import Registercongrats from "./component/registercongrats";
import Dashboard from "./component/dashboard";
import Modules from "./component/modules";
import Module from "./component/module";
import Reports from "./component/reports";


import { BrowserRouter, Route, Routes } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}register`} element={<Register />} />
          <Route path={`${config.baseUrl}registergeneral`} element={<Registergeneral />} />
          <Route path={`${config.baseUrl}registereducation`} element={<Registereducation />} />
          <Route path={`${config.baseUrl}registerpin`} element={<Registerpin />} />
          <Route path={`${config.baseUrl}registerprofile`} element={<Registerprofile />} />
          <Route path={`${config.baseUrl}registercongrats`} element={<Registercongrats />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}modules`} element={<Modules />} />
          <Route path={`${config.baseUrl}module/:id`} element={<Module />} />  
          <Route path={`${config.baseUrl}reports/`} element={<Reports />} />  
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
