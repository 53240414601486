import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { LoginAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Login = () => {

	const [form, setForm] = useState({ email: '', password: '' });
	const [validationError, setvalidationError] = useState({});
	
	
	
	const [Passwordtype, setPasswordType] = useState('password');
	const [Passwordicon, setPasswordIcon] = useState(config.baseUrl+'img/eyes.png');
	
	const handlePasswordToggle = () => {
	   if (Passwordtype==='password'){
		  setPasswordIcon(config.baseUrl+'img/eye.png');
		  setPasswordType('text')
	   } else {
		  setPasswordIcon(config.baseUrl+'img/eyes.png');
		  setPasswordType('password')
	   }
	}

	
	if (Cookies.get('loginSuccessIXRFront')) {
		window.location.href = `${config.baseUrl}dashboard`;
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

  function validate() {
    let emailError = "";
    let passwordError = "";

    if (form.email === '') {
      emailError = "Username is required."
    }
    if (form.password === '') {
        passwordError = "Password is required."
    }
    if (emailError || passwordError) {
        setvalidationError({
          emailError, passwordError
        })
        return false
    } else {
        return true
    }
}

const SubmitForm = async (e) => {
    e.preventDefault()
    const isValid = validate();
    if (!isValid) {

    }
    else {
        let res = await LoginAction(form);
        if (res.success) {
            toast.success(res.msg);
             console.log(res.data);
            Cookies.set('loginSuccessIXRFront', JSON.stringify(res.data));
            setTimeout(() => {
                window.location.href = `${config.baseUrl}dashboard`;
            }, 1200);
        } else {
            toast.error(res.msg);
        }
    }
}

  return (
  
    <>
    
	<div className="dasbord">
		<Toaster />     
    	
		<div className="container">
			<div className="dashboard-menu">
				<div className="row">
					<div className="col-lg-6">
						<div className="laft-post">
							<div className="engine">
							<img src={`${config.baseUrl}img/engine-Image.png`} />
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="password-field fast-block">
							<div className="iXRlabs-logo">
								<img src={`${config.baseUrl}img/iXRlabs-logo.png`} alt="logo" />
							</div>
							<div className="login-registration">
							<div className="Registration">
								<h2>Sign in</h2>
							</div>
							<div className="form-section">
								<form>
									<div className="form-group">
										<label>Email</label>
										<div className="icon-block">
											<span className="lock-icon"><img src={`${config.baseUrl}img/email.png`} /></span>
											<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="Email" onChange={inputHandler} name="email"  />
										</div>
										<span className="invalid-feedback">{validationError.emailError}</span>
									</div>
									<div className="form-group mb-5">
										<label>Password</label>
										<div className="icon-block">
											<span className="lock-icon"><img src={`${config.baseUrl}img/lock.png`} /></span>
											<input className={validationError.passwordError ? 'form-control is-invalid' : 'form-control'}  type={Passwordtype} onChange={inputHandler} placeholder="Password" name="password"  />
											<span className="span-right"  onClick={handlePasswordToggle} >
											<img src={Passwordicon} />
											</span>
										</div>
										<span className="invalid-feedback">{validationError.passwordError}</span>
									</div>
									
									<div className="row align-items-center">
										<div className="col-6 remember">
											<input type="checkbox" />Remember Me
										</div>
										<div className="col-6">
											<div className="your-password">
												<a  href="#">Forgot your password?</a>
											</div>
										</div>
									</div>
									 
								</form>
								
								<div className="Sign-in">
								  <button className="login-btn" type="submit" onClick={SubmitForm} >Sign in</button>
								</div>
								<div className="sign-up">
									<a href={`${config.baseUrl}register`}>Don’t have an account? <span>Sign up</span></a>
								</div>
							
							</div>
							
							</div>
						</div>
					</div>
					
				</div>
			</div>
			
		</div>
	
	</div> 


    </>
  )

}
export default Login;
